.swiper-wrapper {
  min-height: 400px;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 100%;
}

.swiper-pagination-bullet {
  background-color: rgb(183, 234, 248);
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #064378 !important;
}

#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 70%;
  height: 100%;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  .swiper {
    width: 100%;
    height: 100%;
  }
}
  

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
